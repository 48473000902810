/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.2.4/dist/jquery.min.js
 * - /npm/jquery-countdown@2.2.0/dist/jquery.countdown.min.js
 * - /npm/bootstrap@3.3.7/dist/js/bootstrap.min.js
 * - /npm/swiper@3.4.2/dist/js/swiper.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 * - /npm/moment@2.24.0/moment.min.js
 * - /npm/moment-timezone@0.5.27/builds/moment-timezone-with-data.min.js
 * - /npm/scrollreveal@3.4.0/dist/scrollreveal.min.js
 * - /npm/toastr@2.1.4/build/toastr.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
